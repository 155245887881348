<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="bg">
      <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
          <!-- Login v1 -->
          <b-card class="mb-0">
            <h1 class="text-center">
              Login
            </h1>
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group>
                  <label for="Username">Username</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="Username"
                    rules="required"
                  >
                    <b-form-input
                      id="Username"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="Username"
                    />
                    <small class="text-danger">{{ errors[0] ? 'กรอกชื่อผู้ใช้ให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <label for="login-password">Password</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] ? 'กรอกรหัสผ่านให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    <span>remember-me</span>
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  block
                  :disabled="invalid"
                  size="lg"
                  class="btn-gradient"
                >
                  Login
                </b-button>
              </b-form>
            </validation-observer>

          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->
          </b-card>
        <!-- /Login v1 -->
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { email, required } from '@validations'
import {
  BButton, BCard, BCardText,
  // BCardTitle,
  BForm, BFormCheckbox, BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend, BOverlay,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BImg,
    // BLink,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // BCardTitle,
    BOverlay,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      ref: null,
      show: false,
      OTP: '',
      nextstep: 0,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      ag: 1,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
  },
  methods: {
    GetOTP() {
      this.show = true
      const obj = {
        name: this.userEmail,
      }
      this.$http
        .post('/otplogin/store', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data.ref)
          this.ref = response.data.ref
          this.nextstep = 1
          this.show = false
        })
        .catch(error => console.log(error))
    },
    CheckOTP() {
      const obj = {
        name: this.userEmail,
        otps: this.OTP,
        agent_id: this.ag,
      }
      this.$http
        .post('/otplogin/check', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.login()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'OTP ไม่ถูกต้อง',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              agent_id: this.ag,
              // agent_id: 1,
            })
            .then(response => {
              const userData = response.data
              console.log(userData)
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              // console.log(userData.ability)
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .push(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Welcome to Image Dashboard',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged ',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ERROR Email or Password incorrect',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style scoped>
.bg {
  background-image: url('/bg-01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.card {
  border-radius: 14px;
  box-shadow: 0 0 10px 0 rgba(73, 226, 253, 0.836);
  background-color: rgb(255, 255, 255);
}
label {
  font-size: 14px;
  font-weight: 500;
  color: #292929;
}
.btn-gradient {
  background: linear-gradient(to right, #00dbde, #fc00ff);
  border: none !important;
  border-radius: 25px !important;
  color: white;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
